<template>
  <div class="main">
<!--    <Head></Head>-->
    <Menu></Menu>
      <router-view v-slot="{ Component }">
          <keep-alive>
            <component :is="Component"  v-if="$route.meta.keepAlive"/>
          </keep-alive>
          <component :is="Component"  v-if="!$route.meta.keepAlive"/>
        </router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Head from '../components/common/Head'
import Menu from '../components/common/Menu'
import Footer from '../components/common/Footer'

export default {
    name: 'Main',
    components: {
      Head, Menu, Footer
    }
}
</script>

<style scoped lang="less">
.main{
  width: 100%;
  height: 100%;
}
</style>